<template>
  <v-dialog :value="value" class="dialog" persistent width="800">
    <v-card color=#fafafa>
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Update TPR Items</v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row dense>
          <v-col cols="3">
            <v-text-field background-color="#fff" dense outlined label="TPR Begin Date" placeholder="Select Date"
              autocomplete="off" prepend-inner-icon="mdi-calendar" v-model="tpr.dealBeginDate"/>
          </v-col>
          <v-col cols="3">
            <v-text-field background-color="#fff" dense outlined label="TPR End Date"
              placeholder="Select Date" prepend-inner-icon="mdi-calendar" v-model="tpr.dealEndDate"/>
          </v-col>
        </v-row>
        <v-divider class="mb-6 mt-4"/>
        <v-row dense>
          <v-col cols="4">
            <v-autocomplete background-color="#fff" dense outlined label="Department" :items="departments"
              return-object item-text="name" item-value="id" :menu-props="{ maxHeight: 215 }" v-model="tpr.department" clearable />
          </v-col>
          <v-col cols="8">
            <v-autocomplete auto-select-first background-color="#fff" dense outlined label="Manufacturer" :items="manufacturers"
              return-object item-text="description" :menu-props="{ maxHeight: 215 }" v-model="tpr.manufacturer" clearable>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-divider class="mb-6 mt-4"/>
        <v-row dense>
          <v-col>
            <v-text-field dense background-color="#fff" outlined class="input" placeholder="0.00"
               prefix="$" type="number" step="0.01" min="0" label="Case Cost"
              v-model="tpr.caseCost" @change="formatCurrencyInput('caseCost', tpr.caseCost)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00"  prefix="$"
              type="number" step="0.01" min="0" outlined label="AMAP" v-model="tpr.AMAP" @change="formatCurrencyInput('AMAP', tpr.AMAP)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00"  prefix="$"
              type="number" step="0.01" min="0" outlined label="TPR BB" v-model="tpr.BB" @change="formatCurrencyInput('BB', tpr.BB)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00"  prefix="$"
              type="number" step="0.01" min="0" outlined label="TPR Scan" v-model="tpr.scanAllow" @change="formatCurrencyInput('scanAllow', tpr.scanAllow)" autocomplete="off">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00"  prefix="$"
              type="number" step="0.01" min="0" outlined label="ePay TPR" v-model="tpr.ePay" @change="formatCurrencyInput('ePay', tpr.ePay)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense background-color="#fff" placeholder="0.00"  prefix="$"
              type="number" step="0.01" min="0" outlined label="EBA" v-model="tpr.EBA" @change="formatCurrencyInput('EBA', tpr.EBA)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field background-color="#fff" dense outlined label="Count" placeholder="0" type="number" min="0"
              v-model.number="tpr.specialSRPCount" autcomplete="off">
            </v-text-field>
          </v-col>
          <v-icon class="mx-4 mb-6">mdi-at</v-icon>
          <v-col align="right" class="ml-1">
            <v-text-field class="price" background-color="#fff"  prefix="$" type="number"
              step="0.01" min="0" placeholder="0.00" dense outlined v-model.number="tpr.specialSRP" @change="formatCurrencyInput('specialSRP', tpr.specialSRP)" label="TPR Retail"
              autcomplete="off"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-toolbar flat color="#fafafa">
        <v-spacer></v-spacer>
        <v-btn :disabled="hasNoValues" height="40" color="#00B24A" class="ml-2 white--text" @click="$emit('update', tpr)">
          Update<v-icon right>mdi-update</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty } from 'lodash'
import { notification } from '@/mixins/notification'
import Bottleneck from 'bottleneck'

export default {
  name: 'TprBulkEdit',

  mixins: [notification],

  props: ['value', 'selected', 'getTPRs'],

  data () {
    return {
      departments: [],
      manufacturers: [],
      showCurrent: false,
      tpr: {},
      errors: [],
    }
  },

  computed: {
    hasNoValues () {
      return isEmpty(this.tpr)
    },

    formattedBeginDate () {
      return this.tpr.dealBeginDate ? this.moment(this.tpr.dealBeginDate).format(this.$config.date_format) : ''
    },

    formattedEndDate () {
      return this.tpr.dealEndDate ? this.moment(this.tpr.dealEndDate).format(this.$config.date_format) : ''
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getManufacturers()
          this.getDepartments()
          this.tpr = {}
          this.errors = []
        }
      }
    },

    tpr: {
      immediate: true,
      deep: true,
      handler () {
        for (const key in this.tpr) {
          if (key && !this.tpr[key]) {
            delete this.tpr[key]
          }
        }
      }
    }
  },

  methods: {
    formatCurrencyInput (key, value) {
      if (!value) return null
      this.tpr[key] = ((value * 100) / 100).toFixed(2)
    },

    getDepartments () {
      this.$contracts.getDepartments(1)
        .then(response => {
          this.departments = response
        })
    },

    getManufacturers () {
      this.$contracts.getManufacturers()
        .then(response => {
          this.manufacturers = response
        })
    },

    allowedTPRBegin: val => ![0, 1, 2, 3, 4, 5].includes(new Date(val).getDay()),

    allowedTPREnd: val => ![0, 1, 2, 3, 4, 6].includes(new Date(val).getDay()),

    async updateMyData(tpr) {
      return this.$tprs.updateTPR(tpr)
        .catch(err => {
          try {
            this.errors.push(`${tpr.item.orderCode} - ${tpr.adGroup.description} - ${err.response.data.failure_reason}`)
          } catch {
            this.errors.push(`${tpr.item.orderCode} - ${tpr.adGroup.description} Failed to Update`)
          }
        })
    },

    async bulkUpdateTPRs () {
      this.errors = []
      this.showLoader('Updating TPRs', true)

      const limiter = new Bottleneck({
        reservoir: 25,
        reservoirRefreshAmount: 25,
        reservoirRefreshInterval: 5 * 1000,
        maxConcurrent: 25,
        minTime: 0
      })

      const throttledGetMyData = limiter.wrap(this.updateMyData)

      const allThePromises = this.selected.map(tpr => {
        for (const a in tpr) {
          for (const b in this.tpr) {
            if (b === 'dealBeginDate') {
              tpr.dealBeginDate = this.formattedBeginDate
            }
            if (b === 'dealEndDate') {
              tpr.dealEndDate = this.formattedEndDate
            }
            if (a === b) {
              tpr[a] = this.tpr[b]
            }
          }
        }
        return throttledGetMyData(tpr)
      })

      await Promise.all(allThePromises)

      if (this.errors.length) {
        this.showErrors(this.errors)
      } else {
        this.tpr = {}
        this.notify('success', 'TPRs Successfully Updated')
        this.$emit('update:value', false)
      }
      this.getTPRs()
    }
  }
}
</script>
